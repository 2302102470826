import { DeleteObjectStoreFields, GetObjectStoreFields, PostObjectStoreFields, PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiError } from "src/services/ApiService"
import { FinancialProductHistoryAddDTO } from "./ProductHistoryTypes"
import { FinancialProductPersonalAccountDTO } from "./ProductPersonalAccountTypes"
import { FinancialProductAction, FinancialProductStoreFields } from "./financialProductGet"
import { ClearFinancialProductPartialAction } from "./clearFinancialProductPartial"




export interface PersonDataAddressDTO {
    id: number
    street: string
    houseNumber: string
    flatNumber?: string
    town: string
    postalCode: string
    userId: number
}

export interface FinancialProductElectronicAccountDTO {
    id: number
    clientId?: string
    clientAlias?: string
    webPassword?: string
    teleId?: string
    telePassword?: string
    appPassword?: string
    appPin?: string
    authMethod?: string
}

export interface FinancialProductHistoryDTO extends FinancialProductHistoryAddDTO {
    id: number
    // dated: string
    // financialProductHistoryTypeId: number
    // financialProductId: number
    // originOverride?: string

}



export interface FinancialProductCardDebitDTO {
    id: number
    cardNumber?: string
    insurance?: string
    merchant?: string
}


export interface FinancialProductCardCreditDTO {
    id: number
    cardNumber?: string
    insurance?: string
    cardIBAN?: string
    gracePeriod?: number
    interestRate?: number
    yearlyCost?: number
    merchant?: string
    creditAmount: number
    monthlyCost?: number
}

export interface FinancialProductDepositDTO {
    id: number
    amount: number
    dateStart: string
    dateEnd: string
    percentInterest: number
    percentTax: number
    netGain?: number
    moneyOwnerId?: number
}

export interface FinancialProductDTO {
    id: number
    ownerProductId?: number
    financialProductTypeId: number
    name: string
    noticePeriod?: string
    personDataId: number
    addressCorrespondence?: PersonDataAddressDTO
    addressLiving?: PersonDataAddressDTO
    addressRegistered?: PersonDataAddressDTO
    kontomierzAccountId?: number
    shortName?: string
    mail?: string
    descriptionRichText?: string
    electronicAccounts: FinancialProductElectronicAccountDTO
    historyEntries: FinancialProductHistoryDTO[]
    cardDebits: FinancialProductCardDebitDTO
    cardCredits: FinancialProductCardCreditDTO
    deposits: FinancialProductDepositDTO
    personalAccounts: FinancialProductPersonalAccountDTO
}

export interface FinancialProductUpdateDTO {
    ownerProductId?: number
    financialProductTypeId: number
    name: string
    noticePeriod?: string
    personDataId: number
    addressCorrespondenceId?: number
    addressLivingId?: number
    addressRegisteredId?: number
    kontomierzAccountId?: number
    shortName?: string
    mail?: string
    descriptionRichText?: string

}

export interface FinancialProductAddDTO extends FinancialProductUpdateDTO {
    userId?: number
}

export interface FinancialProductsStatusDTO {
    financialProductId: number
    financialProductName: string
    status: string
    ostatniWniosekRozpoczecie?: string
    ostatnieRozpoczecie?: string
    ostatniWniosekRozwiazanie?: string
    ostatnieRozwiazanie?: string
}
export interface FinancialProductListDTO {
    products: FinancialProductsStatusDTO[]
}

export interface PersonDataDTO {
    id: number
    name: string
    surname: string
    email: string
    userId: number

}
export interface PersonDatasDTO {
    personDatas: PersonDataDTO[]
}

export interface UserAddressesDTO {
    addresses: PersonDataAddressDTO[]
}


// GET

// export const GET_FINANCIAL_PRODUCT = 'GET_FINANCIAL_PRODUCT'

// export interface GetFinancialProductFields extends GetObjectStoreFields<FinancialProductDTO> { }

// export interface GetFinancialProductAction extends GetFinancialProductFields {
//     type: typeof GET_FINANCIAL_PRODUCT
// }

// POST

export const POST_FINANCIAL_PRODUCT = 'POST_FINANCIAL_PRODUCT'

export interface PostFinancialProductFields extends PostObjectStoreFields<FinancialProductDTO> { }

export interface PostFinancialProductAction extends PostFinancialProductFields {
    type: typeof POST_FINANCIAL_PRODUCT
}

// PUT

export const PUT_FINANCIAL_PRODUCT = 'PUT_FINANCIAL_PRODUCT'

export interface PutFinancialProductFields extends PutObjectStoreFields<FinancialProductDTO> { }

export interface PutFinancialProductAction extends PutFinancialProductFields {
    type: typeof PUT_FINANCIAL_PRODUCT
}

// DELETE

export const DELETE_FINANCIAL_PRODUCT = 'DELETE_FINANCIAL_PRODUCT'

export interface DeleteFinancialProductFields extends DeleteObjectStoreFields { }

export interface DeleteFinancialProductAction extends DeleteFinancialProductFields {
    type: typeof DELETE_FINANCIAL_PRODUCT
}


// Clear

export const CLEAR_FINANCIAL_PRODUCT = 'CLEAR_FINANCIAL_PRODUCT'

export interface ClearFinancialProductAction{
    type: typeof CLEAR_FINANCIAL_PRODUCT
}


// Get all products list

export const GET_FINANCIAL_PRODUCT_LIST = 'GET_FINANCIAL_PRODUCT_LIST'

export interface GetFinancialProductListFields extends GetObjectStoreFields<FinancialProductListDTO> { }

export interface GetFinancialProductListAction extends GetFinancialProductListFields {
    type: typeof GET_FINANCIAL_PRODUCT_LIST
}

// Get all addresses

export const GET_USER_ADDRESSES = 'GET_USER_ADDRESSES'

export interface GetUserAddressesFields extends GetObjectStoreFields<UserAddressesDTO> { }

export interface GetUserAddressesAction extends GetUserAddressesFields {
    type: typeof GET_USER_ADDRESSES
}

// Get person datas

export const GET_USER_PERSON_DATAS = 'GET_USER_PERSON_DATAS'

export interface GetUserPersonDatasFields extends GetObjectStoreFields<PersonDatasDTO> { }

export interface GetUserPersonDatasAction extends GetUserPersonDatasFields {
    type: typeof GET_USER_PERSON_DATAS
}



/// STORE

export interface ProductStoreState {
    financial_product_get?: FinancialProductStoreFields
    post?: PostFinancialProductFields
    put?: PutFinancialProductFields
    delete?: DeleteFinancialProductFields
    product_list?: GetFinancialProductListFields
    addresses?: GetUserAddressesFields
    person_datas?: GetUserPersonDatasFields
}


export const productInitialState: ProductStoreState = {
}

export type ProductGeneralActionTypes = ClearFinancialProductPartialAction | FinancialProductAction | PostFinancialProductAction | PutFinancialProductAction | DeleteFinancialProductAction | GetFinancialProductListAction | GetUserAddressesAction | GetUserPersonDatasAction | ClearFinancialProductAction;
