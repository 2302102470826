import { Reducer } from 'redux'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { DICTS_FINANCIAL_GET } from './dicts/store/types'
import { CLEAR_FINANCIAL_PRODUCT, DELETE_FINANCIAL_PRODUCT, GET_FINANCIAL_PRODUCT_LIST, GET_USER_ADDRESSES, GET_USER_PERSON_DATAS, POST_FINANCIAL_PRODUCT, productInitialState, PUT_FINANCIAL_PRODUCT } from './product/store/ProductGeneralTypes'
import { CLEAR_PRODUCT_HISTORY, DELETE_PRODUCT_HISTORY, POST_PRODUCT_HISTORY, productHistoryInitialState, PUT_PRODUCT_HISTORY } from './product/store/ProductHistoryTypes'
import { POST_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT, productPersonalAccountInitialState, PUT_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT } from './product/store/ProductPersonalAccountTypes'
import { GET_PRODUCT_REQUIREMENTS, POST_PRODUCT_REQUIREMENT, productRequirementsInitialState } from './product/store/ProductRequirementsTypes'
import { REQUIREMENT_CLOSE } from './requirement/store/requirementClose'
import { REQUIREMENT_DELETE } from './requirement/store/requirementDelete'
import { REQUIREMENT_GET } from './requirement/store/requirementGet'
import { CLEAR_PRODUCT_REQUIREMENT } from './requirement/store/types'
import { GET_PRODUCTS_REQUIREMENTS_MONTH, productsRequirementsMonthInitialState } from './requirements/store/types'
import { GET_PRODUCTS_TREE, productsTreeInitialState } from './tree/store/types'
import { ProductsActionTypes, ProductsStoreState } from './types'
import { FINANCIAL_PRODUCT_GET } from './product/store/financialProductGet'
import { CLEAR_FINANCIAL_PRODUCT_PARTIAL } from './product/store/clearFinancialProductPartial'


export const productsInitialState: ProductsStoreState = {
    requirements_month: productsRequirementsMonthInitialState,
    tree: productsTreeInitialState,
    product: productInitialState,
    dicts: {},
    product_history: productHistoryInitialState,
    product_reqs: productRequirementsInitialState,
    product_acc_personal: productPersonalAccountInitialState,
    requirement: {}
}

export const ProductRequirementsActionNames: string[] =
    [REQUIREMENT_CLOSE, REQUIREMENT_DELETE, REQUIREMENT_GET,
        CLEAR_PRODUCT_REQUIREMENT]

export const ProductsActionNames: string[] =
    [FINANCIAL_PRODUCT_GET, CLEAR_FINANCIAL_PRODUCT_PARTIAL]

const productsReducer: Reducer<ProductsStoreState, ProductsActionTypes> = (state = productsInitialState, action: ProductsActionTypes) => {

    if (ProductRequirementsActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "requirement")
    if (ProductsActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "product")    
    else
        switch (action.type) {
            case GET_PRODUCTS_REQUIREMENTS_MONTH:
                return { ...state, ...action }
            case GET_PRODUCTS_TREE:
                return { ...state, ...action }
            case DICTS_FINANCIAL_GET:
                return reducerStateModifySubstore(state, action, "dicts")
            case POST_FINANCIAL_PRODUCT:
                return { ...state, ...action }
            case PUT_FINANCIAL_PRODUCT:
                return { ...state, ...action }
            case DELETE_FINANCIAL_PRODUCT:
                return { ...state, ...action }
            case CLEAR_FINANCIAL_PRODUCT:
                return { ...state, ...action }
            case GET_FINANCIAL_PRODUCT_LIST:
                {
                    // console.log(action)
                    return {
                        ...state,
                        product: {
                            ...state.product,
                            product_list: {
                                object: action.object
                            }
                        }
                    }
                }
            case GET_USER_PERSON_DATAS:
                return {
                    ...state,

                    product: {
                        ...state.product,
                        person_datas: {
                            object: action.object
                        }
                    }
                }
            case GET_USER_ADDRESSES:
                return {
                    ...state,

                    product: {
                        ...state.product,
                        addresses: {
                            object: action.object
                        }
                    }

                }
            case POST_PRODUCT_HISTORY:
                return { ...state, ...action }
            case PUT_PRODUCT_HISTORY:
                return { ...state, ...action }
            case DELETE_PRODUCT_HISTORY:
                return { ...state, ...action }
            case CLEAR_PRODUCT_HISTORY:
                return { ...state, ...action }
            case GET_PRODUCT_REQUIREMENTS:
                return {
                    ...state,
                    product_reqs: {
                        ...state.product,
                        get: {
                            object: action.object,
                            error: action.error
                        }
                    }
                }
            case POST_PRODUCT_REQUIREMENT:
                return reducerStateModifySubstore(state, action, "product_reqs")
            case POST_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT:
                return reducerStateModifySubstore(state, action, "product_acc_personal")
            case PUT_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT:
                return reducerStateModifySubstore(state, action, "product_acc_personal")
            default:
                return state
        }
}

export default productsReducer

