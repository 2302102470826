import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsStoreState, ProductsThunk } from "../../types"
import { FinancialProductDTO, ProductStoreState } from "./ProductGeneralTypes"

/// FINANCIAL_PRODUCT_GET

export const FINANCIAL_PRODUCT_GET = 'FINANCIAL_PRODUCT_GET'

export interface FinancialProductParams extends ApiCallBaseData {
    id: number
}

export const financialProductGet = (params: FinancialProductParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/financial_products/product/' + params.id + '?extendWithIngredients=true'

        return callGetDispatchedApiSameName<FinancialProductDTO, ProductsStoreState, void, FinancialProductParams>(
            apiService, path, dispatch, FINANCIAL_PRODUCT_GET, undefined, params);

    }
}

export interface FinancialProductStoreFields extends GetObjectStoreFieldsWithParams<FinancialProductDTO, FinancialProductParams> { }

export interface FinancialProductAction extends FinancialProductStoreFields {
    type: typeof FINANCIAL_PRODUCT_GET
}


// export const getFinancialProduct = (id: number): ProductsThunk => {
//     return (dispatch, getState, { apiService }) => {
  
//       var path: string = '/financial_products/product/' + id
//       const state = getState() as unknown as ApplicationState
  
//       return apiService.callDispatchedApi<FinancialProductDTO>(axios.get,
//         path,
//         response => {
//           dispatch({
//             type: GET_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               get: {
//                 object: response.data
//               }
//             },
//           })
//         },
//         undefined,
//         undefined,
//         error => {
//           dispatch({
//             type: GET_FINANCIAL_PRODUCT,
//             product: {
//               ...state.products.product,
//               get: {
//                 error: error
//               }
//             }
//           })
//         }
//       )
//     }
//   }
  
//   export type GetFinancialProductType = typeof getFinancialProduct