import { clearPartialSameNameGeneric } from "src/redux/actions/clearPartialGeneric"
import { ProductsStoreState, ProductsThunk } from "../../types";

/// CLEAR_FINANCIAL_PRODUCT_PARTIAL

export const CLEAR_FINANCIAL_PRODUCT_PARTIAL = 'CLEAR_FINANCIAL_PRODUCT_PARTIAL'

export interface ClearFinancialProductPartialAction {
    type: typeof CLEAR_FINANCIAL_PRODUCT_PARTIAL
}

export const clearFinancialProductPartial = (part: string): ProductsThunk => {
  return (dispatch) => {
    clearPartialSameNameGeneric<ProductsStoreState>(part, CLEAR_FINANCIAL_PRODUCT_PARTIAL, dispatch);
  }
}

